export const paymentPeriod = {
  one: 'one time',
  weekly: 'weekly',
  monthly: 'monthly',
  annually: 'annually'
}

export const calculatePrice = (usdPrice, rate) => {
  return usdPrice ? Math.round(usdPrice * rate) : undefined
}

export const mapPrice = (priceSource, userCurrency ) => {
  if (userCurrency == null) return {
    currency: priceSource.currency,
    symbol: '',
    from: priceSource.from_price_usd,
    to: priceSource.to_price_usd
  }
  return {
    currency: userCurrency.currency,
    symbol: userCurrency.symbol,
    from: calculatePrice(
      priceSource.from_price_usd,
      userCurrency.usdExchangeRate
    ),
    to: calculatePrice(
      priceSource.to_price_usd,
      userCurrency.usdExchangeRate
    )
  }
}

export const priceMapper = (services, userCurrency ) => {
  if (userCurrency == null) return services
  return services.map((service) => {
    return {
      ...service,
      displayPrice: mapPrice(service, userCurrency)
    }
  })
}

export const displayPriceSimple = (priceObj) => {
  if (priceObj == null) return
  const { from, currency } = priceObj
  let { symbol } = priceObj
  let price = ''
  if (symbol == null) symbol = currency
  const spacing = symbol.length > 1 ? ' ' : ''
  const fromPrice = new Intl.NumberFormat().format(parseInt(from))
  price = spacing === ' '
    ? `${fromPrice}${spacing}${symbol}`
    : `${symbol}${fromPrice}`
  return price
}

export const displayPrice = (priceObj, noPriceText = '') => {
  if (priceObj == null) return
  const { from, to, currency } = priceObj
  let { symbol } = priceObj
  let price = ''
  if (symbol == null) symbol = currency
  const spacing = symbol.length > 1 ? ' ' : ''
  if (from) {
    const fromPrice = new Intl.NumberFormat().format(parseInt(from))
    price = spacing === ' '
      ? `${fromPrice}${spacing}${symbol}`
      : `${symbol}${fromPrice}`
    if (to) {
      const toPrice = new Intl.NumberFormat().format(parseInt(to))
      price = spacing === ' '
        ? `${fromPrice} - ${toPrice}${spacing}${symbol}`
        : `${symbol}${fromPrice} - ${symbol}${toPrice}`
    }
    return price
  }
  return noPriceText
}
